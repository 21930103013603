<template>
  <v-layout d-flex column class="fw fh " id="tables"  >
    <v-flex shrink>
      <v-toolbar color="transparent">
        
          <v-btn  :disabled="loading || !user" rounded depressed color="success" @click="addItem">
            <v-icon class="mx-1">mdi-shape-square-rounded-plus</v-icon>
            {{ 'add_table' | t }}
          </v-btn>
          <v-btn class=" mx-1" rounded text @click="downloadURL" :disabled="!tables_map_file" >
            <v-icon class="mx-1" >{{tables_map_file ? "mdi-attachment" : "mdi-file-alert-outline" }}</v-icon>
            <span :class="{'warning--text text--lighten-2': !tables_map_file, 'info--text': tables_map_file}">
              {{ tables_map_file ? 'tables_sketch' : 'tables_sketch_not_uploaded' | t }}
            </span>
          </v-btn>
            
          <v-spacer></v-spacer>

           <v-btn  :disabled="loading || !user || !updated" rounded depressed color="primary" @click="update">
            <v-icon class="mx-1">mdi-content-save</v-icon>
            {{ 'update' | t }}
          </v-btn>
           <v-autocomplete
          :loading="loading || userLoading"
          :disabled="loading"

          class="mx-1"
          rounded
          outlined
          :label="$t('select_user')"
          dense
          hide-details
          :items="users"
          item-value="uid"
          item-text="displayName"
          style="max-width: 300px"
          v-model="user"
          >
            <template v-slot:item="{ attrs, on, item}">
              <v-list-item v-bind="attrs" v-on="on">
                <v-list-item-avatar>
                    <img :src="item.photoURL">
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="item.displayName"></v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
            </template>
          
          </v-autocomplete>
          
      </v-toolbar>
    </v-flex>
    <v-flex grow ref="canvas" class=" d-flex justify-center text-center relative">

      <v-overlay absolute>
        <v-sheet id="svg-canvas" color="transparent">
           <v-overlay absolute  color="transparent" v-if="loading || !tables.length || !user">
             <v-progress-circular  indeterminate  v-if="loading"></v-progress-circular>
              <v-alert v-else icon="mdi-alert" color="warning" text>
                {{ user ? 'empty_sketch' : 'user_not_selected' | t }}
              </v-alert>
           </v-overlay>
        </v-sheet>
      </v-overlay>
    </v-flex>
     <v-flex shrink>
      <v-toolbar color="transparent">
         <template v-if="selected"> 
          <v-icon class="mx-1" v-if="selected.type == 'spacer'">mdi-texture-box</v-icon>
          <v-icon class="mx-1" v-else>mdi-table-furniture</v-icon>
          <span v-if="selected.type != 'spacer'" class="px-1">( <b>{{ selected.number }}</b> )</span>
          <span > {{ selected.name }}</span>
          <v-spacer></v-spacer>
          <v-tooltip top>
              <template v-slot:activator="{attrs, on}">
                <v-btn icon color="info" class="mx-1" v-on="on" v-bind="attrs" @click="editItem" >
                  <v-icon class="mx-1">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>{{ 'edit' | t }} </span>
          </v-tooltip>
             <v-tooltip top>
              <template v-slot:activator="{attrs, on}">
                <v-btn icon color="error" class="mx-1" v-on="on" v-bind="attrs" @click="removeItem" >
                  <v-icon class="mx-1">mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>{{ 'delete' | t }} </span>
          </v-tooltip>
         
       
        </template>
      </v-toolbar>
    </v-flex>
  </v-layout>
</template>

<script>
const ratio = 1.4142 // A4 ratio
const A4 = [3508 , 2480]
const DEGREE = 180 / Math.PI

import { SVG } from '@svgdotjs/svg.js'
const padding = 24
export default {
  data: () => ({
    tables_map_file: null,
    draw: null,
    tables: [],
    users: [],
    user: null,
    defs: {},
    updated: false,
    loading: false,
    userLoading: false,
    move: {
      prevAngle: 0,
      prev: [0, 0],
      point: [0, 0],
      draw: {
        prev: [0, 0],
        point: [0, 0],
      }
    },
    element: {
      selected: null,
      initTransform: null,
      initBBox: null
    },
    drag: {
      started: false,
      rotate: false,
      resize: false
    }
  }),
  watch: {
    breakpoint: {
      handler () {
        const { canvas } = this.$refs
        if (!this.draw || !canvas) return
        const { height } = canvas.getBoundingClientRect()
        this.draw.size(Math.ceil(height * ratio) - (padding * 2), height - (padding * 2))
      },
      deep: true
    },
      user: {
        handler: 'updateView',
        immediate: true
    },
    tables: {
      handler () {
        this.updated = true
      },
      deep: true
    },
    'element.selected' (current, old) {
      if (current) {
        const [ groupInn, Circle, CircleIcon, r, ri ] = current.children()
          Circle.show()
          CircleIcon.show()

           if ( r )
              r.show()
            if ( ri )
              ri.show()

          groupInn.removeClass('hovered')
          groupInn.addClass('selected')
      }
      if (old) {
        const children = old.children()
        const [ innerG, rotateCircle, rotateCircleIcon, resizeCircle, resizeCircleIcon ] = children
        innerG.removeClass('selected')
        rotateCircle.hide()
        rotateCircleIcon.hide()


        if ( resizeCircle )
          resizeCircle.hide()
        if ( resizeCircleIcon )
          resizeCircleIcon.hide()

      }

    } 
  },
  computed: {
    selected () {
      if (!this.element.selected) return 
      return this.tables.find(e => e.id == this.element.selected.id())
    },
    delta () {
      const [x, y] = this.move.point
      const [px, py] = this.move.prev
      return [x - px, y - py]
    },
     deltaDraw () {
        
      const {x, y} = this.move.draw.point
      const {x:px, y:py} = this.move.draw.prev
      return [x - px, y - py]
    },
    primaryColor () {
      const { isDark , defaults } = this.$vuetify.theme
      const { light, dark } = defaults
      return isDark ? dark.primary : light.primary
    },
    collectionRef() {
      return  this.database.collection(`/users/${this.user}/tables`)
    }
  },
  methods: {
   downloadURL () {
     if (!this.tables_map_file) return

     const link = document.createElement('a')
     link.setAttribute('href', this.tables_map_file)
     link.setAttribute('target', '_blank')
     document.body.append(link)
     link.click()
     link.remove()
      // window.location = this.tables_map_file
   },
    update () {
      const batch = this.database.batch()
      this.tables.forEach(table => {
        const doc = this.collectionRef.doc(table.id)
        const {
          name,
          number,
          type,
          seats,
          transform
        } = table
        const data = {
          name,
          number,
          type,
          seats,
          transform
        }
        Object.keys(data).forEach(key => {
          if(data[key] === undefined)
            delete data[key];
        })
        batch.update(doc, data)

      })
      this.userLoading = this.loading = true
      batch.commit().then(() => this.notify(this.$t(`update_successfuly`), {
                      color: 'success',
                      icon: 'mdi-check-circle'
                  })).catch(this.handelError).finally(() => this.updated = this.loading = this.userLoading = false)
   
    },
    async updateView () {
      this.tables_map_file = null
      if (this.draw)
        this.draw.clear()
      if (!this.user) return
       if (this.draw) {
          this.$set(this.defs, 'pattern', this.draw.pattern(10, 10, function(add) {
            add.line(0,0,0,10).stroke({ color: '#888888',  width: 3 })
          }).attr({patternTransform: 'rotate(45)'}))
       }
       
      this.loading = true

      this.tables = []
      const userDataDoc = await this.database.collection('users').doc(this.user).get()
      const { tables_map_file } = userDataDoc.data()
      this.tables_map_file = tables_map_file
      this.collectionRef.get().then(ref => {
    
        this.tables = ref.docs.map(doc => ({ id: doc.id, ...doc.data() }))
      
        this.tables.forEach(table => {
          this.addTableToCanvas(table, 0,0)
        })
        
      }).catch(this.handelError).finally(() => this.updated = this.loading = false)
    },
    getElementPosition (el) {
      const { x, y } = el.node.getBoundingClientRect()
      return this.draw.point({ x, y })
    },
    addTableToCanvas (table) {
      
        const vm = this
        const { draw } = vm
        const { type, transform } = {...table}

  


        const group = draw.group().translate(transform.x, transform.y).rotate(transform.rotate).id(table.id)
        const groupInner = draw.group().attr({  'stroke-linecap': "round" }) 
        
      
        const el = ['square', 'rect', 'spacer'].includes(type) ? draw.rect().size(transform.w, transform.h) : draw.circle().radius(transform.w/2).cx(transform.w/2).cy(transform.h/2)

        group.on('mousedown', function ({ x, y }) {
        
          console.log(x, y);
 
          
          
          this.attr({ class: 'grabbing'})
          vm.drag.started = true
          vm.move.prev = [x , y]
          vm.move.draw.prev = vm.draw.point({x, y})
          vm.element.selected = this
          vm.element.initTransform = this.transform()
          const { cx , cy } = vm.element.initBBox = this.bbox()
          const { x:ex, y:ey} = vm.draw.point({x, y})
          const { x: px, y: py} = vm.getElementPosition(this)

          vm.move.prevAngle =  Math.atan2(ex - (px + cx), ey - (py + cy)) * DEGREE
 
        })

        group.on('mouseover', function () {
          if (!groupInner.hasClass('selected'))
            groupInner.addClass('hovered')

          if (!vm.drag.started)
            this.attr({  class: 'grab'})
        })

        group.on('mouseout', function () {
          groupInner.removeClass('hovered')
        })


       
       
         
    
       
 
       
       const rect = el.stroke(type == 'spacer' ? { color: '#888888', opacity: 0.9,  width: 3 } :'#38b6ff') 
          .fill(type == 'spacer' ? this.defs.pattern : '#eeeeee').attr({ rx: 5 })
        groupInner.add(rect)
        
        
        
        
          const title = draw.plain(table.name).translate( transform.w / 2, transform.h/2).attr({ name: 'name' })
          title.font({anchor: 'middle', size: 50,});
          groupInner.add(title)
     
        if (type != 'spacer') {
          const number = draw.plain(table.number).translate(transform.w / 2, 90).attr({ name: 'number' })
           number.font({anchor: 'middle', size: 100,});
          groupInner.add(number)
          const seats = draw.plain(table.taken+'/'+table.seats).translate((transform.w / 2), 87*3).attr({ name: 'seats' })
          seats.font({anchor: 'middle', size: 50,});
          groupInner.add(seats)
          // const seatsIcon = draw.path('M4,18V21H7V18H17V21H20V15H4V18M19,10H22V13H19V10M2,10H5V13H2V10M17,13H7V5A2,2 0 0,1 9,3H15A2,2 0 0,1 17,5V13Z')
          //   .fill('#9c9c9c')
          // const { w:iconW } = seatsIcon.bbox()
          // seatsIcon.translate((transform.w / 2) - (iconW/2) , 175).scale(2)
          //   175, 215
          // groupInner.add(seatsIcon)
        } 
        
        
  
        
       
       
        
        
        
        const rotate = draw.circle(60).stroke('rgb(255, 123, 0)').fill('rgba(255, 123, 0, 0.5)').translate(-7.5*3, -7.5*3 ).attr({ style: 'cursor: crosshair;' })
        const rotateIcon = draw.path('M12,5C16.97,5 21,7.69 21,11C21,12.68 19.96,14.2 18.29,15.29C19.36,14.42 20,13.32 20,12.13C20,9.29 16.42,7 12,7V10L8,6L12,2V5M12,19C7.03,19 3,16.31 3,13C3,11.32 4.04,9.8 5.71,8.71C4.64,9.58 4,10.68 4,11.88C4,14.71 7.58,17 12,17V14L16,18L12,22V19Z')
        .scale(0.8*3).translate(-9, -9 ).fill('white')
       rotate.on('mousedown', function () {
          vm.drag.rotate = true
        })
        rotate.hide()
        rotateIcon.hide()
       
       

    
        group.add(groupInner)
        group.add(rotate)
        group.add(rotateIcon)



        if (type == 'spacer') { 

          const { w:gw, h:gh} = groupInner.bbox()
          const resize = draw.circle(60).stroke('rgb(56, 182, 255)').fill('rgba(56, 182, 255, 0.5)').translate(gw-30, gh-30 ).attr({style:'cursor: nwse-resize;'})
          const resizeIcon = draw.path('M22,22H20V20H22V22M22,18H20V16H22V18M18,22H16V20H18V22M18,18H16V16H18V18M14,22H12V20H14V22M22,14H20V12H22V14Z').scale(3)
          .translate(gw - 18, gh - 18 ).fill('white')

          group.add(resize);
          group.add(resizeIcon);
          resize.hide()
          resizeIcon.hide()

          resize.on('mousedown', function () {
            vm.drag.resize = true
          })


        }

        
      return group
    },

    mousemove ({ x, y }) {
      if (!this.drag.started) return

      const { selected } = this.element
      this.move.point = [x, y]
      this.move.draw.point = this.draw.point({x, y})

      if (this.drag.rotate) {
        const { cx , cy } = this.element.selected.bbox()
        const { x:ex, y:ey} = this.draw.point({x, y})
        const { x: px, y: py} = this.getElementPosition(this.element.selected)


        const angle =  Math.atan2(ex - (px + cx), ey - (py + cy)) * DEGREE
        const deltaAngle = this.move.prevAngle - angle
        this.element.selected.rotate(deltaAngle)

 
        this.move.prevAngle = angle 
      } else if (this.drag.resize) {
        const [ dx, dy ] = this.deltaDraw
        const [g, , ,c,l]= selected.children() 
        const [el, text] = g.children()
        const { w, h } = el.bbox()
        const ws = w+dx < 25 ? 25 : w+dx
        const hs =  h+dy < 25 ? 25 : h+dy
        text.transform({ translate: { x: ws/2, y: hs/2 } })
        c.transform({ translate: { x: ws - 30 , y: hs - 30  } })
        l.transform({ translate: { x: ws - 18 , y: hs - 18  } })

        el.size(ws, hs )

         
      }
      else {
        const [ dx, dy ] = this.deltaDraw
        selected.translate(dx, dy)
      }

      const { rotate, translateX, translateY } = this.element.selected.transform()
      const { w, h } = this.element.selected.bbox()
      
      this.selected.transform.x = translateX
      this.selected.transform.y = translateY
      this.selected.transform.w = w
      this.selected.transform.h = h
      this.selected.transform.rotate = rotate
      
      
      this.move.draw.prev = this.draw.point({x, y})
      this.move.prev = [x, y]
    },
    
    mouseup () {
      if (!this.drag.started) return

      this.drag.started = false
      this.drag.rotate = false
      this.drag.resize = false
      

      this.move.prev = [0, 0]
      this.move.point = [0, 0]
      this.move.draw.prev = [0, 0]
      this.move.draw.point = [0, 0]

      
      this.element.selected.attr({ class: 'grab'})
      // this.element.selected = null
    },
    async addItem () {
      const vm = this
      // const { h, w } = this.draw.bbox()
      const [ w, h ] = A4
      // console.log(A4, [ h, w]);
      // console.log(this.draw.bbox());
      vm.openDialog('table', { title: 'add_table', icon: 'mdi-table-chair', confirmText: 'add_the_table', size: this.tables.length }, (data) => {
        const doc = vm.collectionRef.doc()
        data.id = doc.id
        data.taken = 0
        data.transform = {
          x: w/2,
          y: h/2,
          rotate: 0,
          w: ['square', 'circle', 'spacer'].includes(data.type) ? 300 : 600,
          h: 300
        }
       
 
        vm.dialogLoading(true)
       doc.
          set(data)
              .then(() => {
                  vm.notify(vm.$t(`created_successfuly`, data.name), {
                      color: 'success',
                      icon: 'mdi-check-circle'
                  })
                  this.tables.push(data)
                  const g = this.addTableToCanvas(data);
                  vm.element.selected = g 
                  vm.closeDialog()
              }).catch(vm.handelError).finally(() => vm.dialogLoading(false))
              

      }, { maxWidth: 650, scrollable:true })
    },
    editItem () {
      const vm = this
      const values =  { ...this.selected }
      vm.openDialog('table', { title: 'edit_table', icon: 'mdi-table-chair', confirmText: 'update', values, size: this.tables.length }, (data) => {
        
        const  { id } = vm.selected
        const element = vm.tables.find(e => e.id == id) 
        
        const updateElement = () => {

          Object.keys(data).forEach(key => {
            element[key] = data[key]
          })
          if (values.type != data.type ) {
            element.transform.w = ['square', 'circle', 'spacer'].includes(data.type) ? 300 : 600,
            element.transform.h = 300
          }
          this.draw.each(function (i, children) {
            if (this.id() == id) {
              vm.element.selected = children[i] = vm.addTableToCanvas(element)
              this.remove()
            }
          })
        }
   
 
      
       
 
        const doc = vm.collectionRef.doc(id)
        vm.dialogLoading(true)
        doc.
            update(data)
                .then(() => {
                    vm.notify(vm.$t(`updated_successfuly`, data.name), {
                        color: 'success',
                        icon: 'mdi-check-circle'
                    })
                    updateElement()
                     
                    vm.closeDialog()
                }).catch(vm.handelError).finally(() => vm.dialogLoading(false))
              

      }, { maxWidth: 650, scrollable:true })
    },
    removeItem () {
      const vm = this
      vm.openDialog('confirm', {
          title: 'confirm_deletion',
          text: 'confirm_deletion_text',
          name: this.selected.name,
          confirmText: 'delete',
          confirmIcon: 'mdi-delete',
          color: 'error'
      }, () => {
           const { id, name } = vm.selected
           
          vm.dialogLoading(true)
          vm.collectionRef.doc(id).delete()
              .then(() => {
                  vm.notify(vm.$t('deleted_successfuly', name), {
                      color: 'success',
                      icon: 'mdi-check-circle'
                  })
                  vm.draw.each(function () {
                    if (id == this.id())
                      this.remove()
                  })
                  vm.tables = vm.tables.filter(e => e.id != id)

                  vm.dialogLoading(false)
                  vm.closeDialog()
              }).catch(vm.handelError).finally(() => vm.dialogLoading(false))

      })
    }
  },
  async mounted () {
    const vm = this
    const { canvas } = this.$refs
    let { height } = canvas.getBoundingClientRect()
    this.draw = SVG()
    .addTo('#svg-canvas')
    .attr({ style: 'background: #fff; ', class: 'elevation-1'})
    .size(Math.ceil(height * ratio) - (padding * 2), height - (padding * 2)).viewbox(0, 0, A4[0], A4[1])

    this.draw.on('click', function (ev) {
      if(ev.target === this.node) vm.element.selected = null;
    })

    this.$set(this.defs, 'pattern', this.draw.pattern(10, 10, function(add) {
      add.line(0,0,0,10).stroke({ color: '#6A6A6A',  width: 3 })
    }).attr({patternTransform: 'rotate(45)'}))



 

  },
  created () {
    this.loading = true
    this.$api.get('/users').then(({ data }) => {
      this.users = data
    }).catch(this.handelError).finally(() => this.loading = false)
    document.addEventListener('mousemove', this.mousemove)
    document.addEventListener('mouseup', this.mouseup)

  },
  beforeDestroy () {
    document.removeEventListener('mousemove', this.mousemove)
    document.removeEventListener('mouseup', this.mouseup)
  }
}
</script>


<style lang="scss">
.grabbing {
  cursor: -webkit-grabbing !important;
}
.grab {
  cursor: -webkit-grab !important;
}
#tables {
   -webkit-user-select: none;  
  -moz-user-select: none;    
  -ms-user-select: none;      
  user-select: none;
  .selected {
    outline: 1.5px dashed rgb(255, 123, 0);
  }
  .hovered {
    outline: 1.5px dashed rgba(255, 123, 0, 0.7);
  }
  .outlined {
    outline-color: rgb(0, 0, 255);;
    outline-style: dashed;
    outline-width: 1px;
    outline-offset: 0px;
    border: 5px solid #000;
  }
   .small { font: italic 13px sans-serif; }
    .heavy { font: bold 30px sans-serif; }

    /* Note that the color of the text is set with the    *
     * fill property, the color property is for HTML only */
    .Rrrrr { font: italic 40px serif; fill: red; }
}
</style>