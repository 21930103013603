var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"fw fh ",attrs:{"d-flex":"","column":"","id":"tables"}},[_c('v-flex',{attrs:{"shrink":""}},[_c('v-toolbar',{attrs:{"color":"transparent"}},[_c('v-btn',{attrs:{"disabled":_vm.loading || !_vm.user,"rounded":"","depressed":"","color":"success"},on:{"click":_vm.addItem}},[_c('v-icon',{staticClass:"mx-1"},[_vm._v("mdi-shape-square-rounded-plus")]),_vm._v(" "+_vm._s(_vm._f("t")('add_table'))+" ")],1),_c('v-btn',{staticClass:" mx-1",attrs:{"rounded":"","text":"","disabled":!_vm.tables_map_file},on:{"click":_vm.downloadURL}},[_c('v-icon',{staticClass:"mx-1"},[_vm._v(_vm._s(_vm.tables_map_file ? "mdi-attachment" : "mdi-file-alert-outline"))]),_c('span',{class:{'warning--text text--lighten-2': !_vm.tables_map_file, 'info--text': _vm.tables_map_file}},[_vm._v(" "+_vm._s(_vm._f("t")(_vm.tables_map_file ? 'tables_sketch' : 'tables_sketch_not_uploaded'))+" ")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.loading || !_vm.user || !_vm.updated,"rounded":"","depressed":"","color":"primary"},on:{"click":_vm.update}},[_c('v-icon',{staticClass:"mx-1"},[_vm._v("mdi-content-save")]),_vm._v(" "+_vm._s(_vm._f("t")('update'))+" ")],1),_c('v-autocomplete',{staticClass:"mx-1",staticStyle:{"max-width":"300px"},attrs:{"loading":_vm.loading || _vm.userLoading,"disabled":_vm.loading,"rounded":"","outlined":"","label":_vm.$t('select_user'),"dense":"","hide-details":"","items":_vm.users,"item-value":"uid","item-text":"displayName"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
var item = ref.item;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-avatar',[_c('img',{attrs:{"src":item.photoURL}})]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(item.displayName)}})],1)],1)]}}]),model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}})],1)],1),_c('v-flex',{ref:"canvas",staticClass:" d-flex justify-center text-center relative",attrs:{"grow":""}},[_c('v-overlay',{attrs:{"absolute":""}},[_c('v-sheet',{attrs:{"id":"svg-canvas","color":"transparent"}},[(_vm.loading || !_vm.tables.length || !_vm.user)?_c('v-overlay',{attrs:{"absolute":"","color":"transparent"}},[(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":""}}):_c('v-alert',{attrs:{"icon":"mdi-alert","color":"warning","text":""}},[_vm._v(" "+_vm._s(_vm._f("t")(_vm.user ? 'empty_sketch' : 'user_not_selected'))+" ")])],1):_vm._e()],1)],1)],1),_c('v-flex',{attrs:{"shrink":""}},[_c('v-toolbar',{attrs:{"color":"transparent"}},[(_vm.selected)?[(_vm.selected.type == 'spacer')?_c('v-icon',{staticClass:"mx-1"},[_vm._v("mdi-texture-box")]):_c('v-icon',{staticClass:"mx-1"},[_vm._v("mdi-table-furniture")]),(_vm.selected.type != 'spacer')?_c('span',{staticClass:"px-1"},[_vm._v("( "),_c('b',[_vm._v(_vm._s(_vm.selected.number))]),_vm._v(" )")]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.selected.name))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","color":"info"},on:{"click":_vm.editItem}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mx-1"},[_vm._v("mdi-pencil")])],1)]}}],null,false,3209887991)},[_c('span',[_vm._v(_vm._s(_vm._f("t")('edit'))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1",attrs:{"icon":"","color":"error"},on:{"click":_vm.removeItem}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mx-1"},[_vm._v("mdi-delete")])],1)]}}],null,false,3682197119)},[_c('span',[_vm._v(_vm._s(_vm._f("t")('delete'))+" ")])])]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }